import React from "react";
import { withRouter } from "react-router";
import "./OurWorksSection.scss";
import pointerIcon from "../../assets/images/responsibility/pointer-icon.svg";
import IgnisFoundationIcon from "../../assets/images/ignis-foundation-icon.svg";

class SlidingList extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="item">
                    <div className="details text-center text-white">
                        <div className="image overflow-hidden border-radius">
                            <div className="brand-placeholder">
                                <img src={IgnisFoundationIcon} alt="IGNIS Foundation Carity Trust" />
                            </div>
                            <img src={`${this.props.eventMainImg}`} alt={`${this.props.eventName}`} className="animation-grow" />
                        </div>
                        <div className="desc">
                            <img src={pointerIcon} className="pointer-icon" alt={`${this.props.eventName}`} />
                            <h3 className="text-red">{this.props.eventYear}</h3>
                            <h5 className="text-blue">{this.props.eventName}</h5>
                            <p className="text-black">{this.props.eventPlace}</p>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}
export default withRouter(SlidingList);