import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../Screens/Home/Home";
import Layout from "../Layout/Layout";
import ContactUs from "../Screens/ContactUs/ContactUs";
import Pricing from "../Screens/Pricings/Pricings";
class InnerRoutes extends React.Component {
  render() {
    const {
      match: { url },
    } = this.props;
    return (
      <Layout {...this.props}>
        <Switch>
          <Route exact path={`/`} component={Home} />
          <Route path="/pricing" component={Pricing} />
          <Route path="/contact-us" component={ContactUs} />
        </Switch>
      </Layout>
    );
  }
}

export default InnerRoutes;
