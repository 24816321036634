import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import ourVision from "../../../assets/images/ignis-foundation-vision.png";
import ourMission from "../../../assets/images/digital-skills.png";

class VisionMission extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section className="vision-mission">
          <div className="container">
            <div className="floating-content">
              <div className="vision">
                <div className="d-flex border-right">
                  <div>
                    <img src={ourVision} alt="Ignis FOundation Vision" />
                  </div>
                  <div>
                    <h4 className="text-blue">Our Vision</h4>
                    <p>Build an equitable digital world</p>
                  </div>
                </div>
              </div>
              <div className="mission">
                <div className="d-flex border-right">
                  <div>
                    <img src={ourMission} alt="Ignis FOundation Mission" />
                  </div>
                  <div>
                    <h4 className="text-blue">Our Mission</h4>
                    <ul className="mb-0">
                      <li>Retrain current workforce with digital skills</li>
                      <li>
                        Develop a new workforce equipped with advanced
                        technological skills.
                      </li>
                      <li>Mentor in training and career guidance.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default VisionMission;
