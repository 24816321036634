import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import aboutImage from "../../assets/images/ignis-foundation-about.png";
import "./About.scss";

class AboutSection extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section
          className="about-ignis section-bottom-padding pattern-stipped-red-br"
          id="aboutus"
        >
          <div className="container">
            <Row gutter={[12, 22]}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <h2 className="font-weight-bolder text-uppercase mb-0 title-font">
                  About us
                </h2>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 18 }}
                lg={{ span: 16 }}
              >
                <div class="align-middle">
                  <h4 className="text-blue">
                    Ignis foundation is a Christian Non-Profit organization that
                    helps to{" "}
                    <span className="text-red">
                      bring more people into the digital economy through
                      technology
                    </span>{" "}
                    job training and career mentorship.
                  </h4>
                  <p>
                    Our world is becoming more and more digitized and automated.
                    These changes are already starting to disrupt traditional
                    jobs and manual labors resulting in loss of livelihoods.
                  </p>
                  <p>
                    Our goal is to reskill human resources digitally and build
                    the next generation of human resources with advance
                    technological skills.
                  </p>
                  <p>
                    At Ignis, our mission is to empower and enable them to gain
                    access to high paying jobs. At Ignis we are inspired by
                    Apostle Paul’s call to “Build one another up” -1 Thess 5:11.
                    We build a network of mentors who have specializations in
                    specific technological areas that counsel and guide the
                    participants. Our unique project-based approach to train the
                    workforce with an immersive project-based training will help
                    the participants gain real project experience.
                  </p>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 6 }}
                lg={{ span: 8 }}
              >
                <img src={aboutImage} alt="Ignis Foundation" />
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default AboutSection;
