import React from "react";
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import "./IgnisAngelSection.scss";
import IgnisAngelList from "./IgnisAngelList";
import Slider from "react-slick";
import architects from "../../assets/images/ignis-angels/architects.jpg";
import aiMl from "../../assets/images/ignis-angels/ai-ml.jpg";
import dataScientist from "../../assets/images/ignis-angels/data-scientist.jpg";
import dataAnalyst from "../../assets/images/ignis-angels/data-analyst.jpg";
import cyberSecurity from "../../assets/images/ignis-angels/cyber-security.jpg";
import qaAnalyst from "../../assets/images/ignis-angels/qa-analyst.jpg";
import projectManager from "../../assets/images/ignis-angels/project-manager.jpg";
import techRecruiters from "../../assets/images/ignis-angels/tech-recruiters.jpg";
import appDevelopers from "../../assets/images/ignis-angels/app-developers.jpg";
import CloudDevelopers from "../../assets/images/ignis-angels/cloud-developers.jpg";
import arrow from "../../assets/images/arrow.svg";

const ignisAngel = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
            }
        },
        {
            breakpoint: 900,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                arrows: false,
            }
        },
        {
            breakpoint: 580,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false
            }
        },
        {
            breakpoint: 400,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false
            }
        }
    ]
};

class IgnisAngelSection extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section className="section-padding bg-light pattern-stipped-blue-bl ignis-angel" id="ignisangels">
                    <div className="container">
                        <Row gutter={[20, 20]} className="mb-3" >
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <div class="align-middle text-center">
                                    <h2 className="font-weight-bolder text-blue title-font text-uppercase mb-2">Ignis Angels</h2>
                                    <p>Ignis angels are well experienced mentors who are playing key roles in the digital tech projects. Ignis angels dedicate a minimum of 1 hour a week to mentor, help and guide candidates who are seeking to build their career in the technology world.</p>
                                    <h4 className="text-red mb-3">The various types of Ignis Angels include</h4>
                                </div>
                            </Col>
                        </Row>

                        <div className="various-ignis-angel">
                            <Slider {...ignisAngel}>
                                <IgnisAngelList ignisAngelimage={architects} ignisAngelName="Web Architects" />
                                <IgnisAngelList ignisAngelimage={aiMl} ignisAngelName="AI/ML Developers" />
                                <IgnisAngelList ignisAngelimage={dataScientist} ignisAngelName="Data Scientists" />
                                <IgnisAngelList ignisAngelimage={dataAnalyst} ignisAngelName="Data Analysts" />
                                <IgnisAngelList ignisAngelimage={cyberSecurity} ignisAngelName="Cybersecurity specialists" />
                                <IgnisAngelList ignisAngelimage={qaAnalyst} ignisAngelName="QA Analysts" />
                                <IgnisAngelList ignisAngelimage={projectManager} ignisAngelName="Project Managers" />
                                <IgnisAngelList ignisAngelimage={techRecruiters} ignisAngelName="Tech Recruiters" />
                                <IgnisAngelList ignisAngelimage={appDevelopers} ignisAngelName="Application Developers" />
                                <IgnisAngelList ignisAngelimage={CloudDevelopers} ignisAngelName="Cloud developers" />
                            </Slider>
                        </div>

                        <Row gutter={[20, 20]} >
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} className="mt-5 text-center">
                                <a className="btn btn-link btn-lg">Become an Ignis Angel <img src={arrow} alt="ignis foundation button"/></a>
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default IgnisAngelSection
