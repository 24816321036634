import React from "react";
import { Row, Col, BackTop } from "antd";
import "./Footer.scss";
import mainlogo from "../assets/images/ignis-foundation-logo.svg";
import moveTop from "../assets/images/up-arrow.svg";
export default class Footer extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section className="footer py-5" id="contact">
          <div className="container">
            <Row>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <div className="footer-logo mb-3 mb-lg-5">
                  <img
                    src={mainlogo}
                    className="pr-4 mr-4"
                    alt="Ignis Foundation"
                  />
                </div>
                <div className="social-media">
                  <h3 className="text-red text-uppercase">Follow us</h3>
                  <ul className="d-flex p-0 m-0">
                    <li>
                      <a
                        href="https://www.facebook.com/ignisproacademy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-facebook" aria-hidden="true" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/ignis_pro_academy/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-instagram" aria-hidden="true" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/IGNIS_Academy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-twitter" aria-hidden="true" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/ignis-pro-academy/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-linkedin" aria-hidden="true" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/@IGNISProAcademy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-youtube" aria-hidden="true" />
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
              >
                <h3 className="text-red">Get In Touch</h3>
                <div className="contact-item">
                  <div className="icon">
                    <i className="fa fa-whatsapp" aria-hidden="true" />
                  </div>
                  <div className="details">
                    <span>USA</span>
                    <p>
                      <a href="tel:+1(312) 989-0702">+1(312) 989-0702</a>
                    </p>
                  </div>
                </div>
                <div className="contact-item">
                  <div className="icon">
                    <i className="fa fa-envelope-o" aria-hidden="true" />
                  </div>
                  <div className="details">
                    <span>Email</span>
                    <p>
                      <a href="mailto:ignisfoundation@gmail.com">
                        ignisfoundation@gmail.com
                      </a>
                    </p>
                  </div>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
              >
                <div className="contact-item mt-sm-2 mt-md-3 mt-lg-4">
                  <div className="icon">
                    <i className="fa fa-whatsapp" aria-hidden="true" />
                  </div>
                  <div className="details">
                    <span>India</span>
                    <p style={{ marginBottom: 5 }}>
                      <a href="tel:+91-63836 23926">+91-63836 23926</a>
                    </p>
                    <p>
                      <a href="tel:+91-9443210651">+91-94432 10651</a>
                    </p>
                  </div>
                </div>
                <div className="contact-item">
                  <div className="icon">
                    <i className="fa fa-clock-o" aria-hidden="true" />
                  </div>
                  <div className="details">
                    <span>Hours</span>
                    <p>Mon-Fri 9:00AM - 6:00 PM (IST) </p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="text-center pt-3 border-top">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <p className="mb-0 pt-3">
                  <span>
                    © {new Date().getFullYear()} Copyright IGNIS FOUNDATION. All
                    Right Reserved.
                  </span>
                </p>
              </Col>
            </Row>
          </div>
        </section>

        <BackTop>
          <div className="back-to-top">
            <img src={moveTop} alt="move to top" />
            <span className="d-block">UP</span>
          </div>
        </BackTop>
      </React.Fragment>
    );
  }
}
