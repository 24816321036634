import TitleSection from "../../Components/TitleSection/TitleSection";
import { Row, Col } from "antd";
import "./Pricings.scss";

export default function Pricing() {
  return (
    <>
      <TitleSection className="pricing" titleName="Courses & Pricing" />

      <section className="pricings pattern-stipped-red-br py-5">
        <div className="container">
          <Row gutter={[30, 20]} align="center" className="pb-3">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <div className="card">
                <h4 className="text-blue">Professional IT Courses</h4>
                <div className="price">3 Months - Rs 6000 Per Courses.</div>

                <ul>
                  <li>Web Dev Professional</li>
                  <li>E-commerce Professional</li>
                  <li>DevOps/QA Testing Professional</li>
                  <li>Digital Marketing Professional</li>
                  <li>Cloud Computing</li>
                  <li>Data Science</li>
                  <li>Cyber Security</li>
                  <li>Product Management</li>
                  <li>Sales Operations</li>
                  <li>Project Management</li>
                  <li>ERP Operations</li>
                  <li>Finance Operations</li>
                  <li>Customer Service</li>
                  <li>Marketing</li>
                </ul>
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <div className="card">
                <h4 className="text-blue">Spoken English Class</h4>
                <div className="border-top pt-3 mt-3" />

                <p className="text-left">
                  School Students
                  <span className="price d-block">Rs 1000/Month</span>{" "}
                </p>
                <p className="text-left">
                  College Students
                  <span className="price d-block">Rs 1500/Month</span>
                </p>
                <p className="text-left">
                  Working Professionals
                  <span className="price d-block">Rs 2000/Month</span>{" "}
                </p>
              </div>
              <div className="card mt-3">
                <h4 className="text-blue">Spoken Hindi</h4>
                <div className="price">Rs 700/Month</div>
                <ul>
                  <li>School Students Only </li>
                </ul>
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
              className="center-contents"
            >
              <div className="card mb-3">
                <h4 className="text-blue">Weekly Bootcamps</h4>
                <div className="border-top pt-3 mt-3" />

                <p className="text-left">
                  Medium Level
                  <span className="price d-block">1 hr - Rs 500</span>
                </p>
                <p className="text-left">
                  High Level
                  <span className="price d-block">4 hrs - Rs 3000</span>
                </p>
              </div>
              <div className="card">
                <h4 className="text-blue">Free Of Cost Courses</h4>
                <div className="price">Free</div>
                <ul>
                  <li>Free Online Webinars </li>
                  <li>Free Women Empowerment Camps </li>
                  <li>Free Tailoring Classes For Women </li>
                  <li>Free Craftwork Classes For Women</li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}
