import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { Row, Col } from "antd";
import TitleSection from "../../Components/TitleSection/TitleSection";
import "./ContactUs.scss";
import ContactForm from "./ContactForm";

class ContactUs extends React.Component {
  render() {
    return (
      <React.Fragment>
        <TitleSection
          className="contactus"
          welcomeText="WELCOME TO IGNIS FOUNDATION"
          titleName="Contact Us"
        />
        <section className="get-in-touch pattern-stipped-red-br py-5">
          <div className="container">
            <Row gutter={[30, 20]} align="center" className="pb-3">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                className="center-contents"
              >
                <div className="title-section text-center mb-4">
                  <h1 className="text-blue text-center d-inline border-left-3 pl-3  border-danger  pr-3">
                    Contact Us
                  </h1>
                  <p className="mt-4">
                    Fill out the form below and we will contact you as soon as
                    possible.
                  </p>
                </div>
              </Col>
            </Row>

            <Row gutter={[30, 20]} align="center" className="pb-3">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 20 }}
                lg={{ span: 13 }}
                className="center-contents"
              >
                <div className="title-section text-center mb-4">
                  <ContactForm />
                </div>
              </Col>
            </Row>
          </div>
        </section>
        <section className="ignis-location bg-light-gray">
          <div className="container">
            <Row align="center">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <div className="map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3943.653333442477!2d77.37785971427499!3d8.724430296088126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b043b89a8327477%3A0x797b0341ebfb8064!2sIgnis%20Academy!5e0!3m2!1sen!2sin!4v1666666989627!5m2!1sen!2sin"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                className="center-contents"
              >
                <div className="address p-4 p-lg-5  p-mob">
                  <div className="title-section mb-4">
                    <h3 className="text-blue d-inline border-left-3 pl-3  border-danger  pr-3">
                      Our Main Office
                    </h3>
                  </div>
                  <h4 className="text-red">IGNIS FOUNDATION</h4>
                  <h5>Operational Address</h5>
                  <p className="mt-4">
                    768A,2nd Floor, R.C. Diocese Building, 3-Lamp Bus
                    Stop,Vickramasingapuram, Tirunelveli - 627425, Tamilnadu,
                    India
                  </p>
                  <h5 className="mt-4">Business Address</h5>
                  <p>
                    170/10, Mukil Street, Pothigai Nagar, Dana,
                    Vickramasingapuram, Tirunelveli - 627425, Tamilnadu, India
                  </p>
                  <div className="d-sm-block d-md-flex d-lg-flex mt-4">
                    <ul className="mb-sm-2">
                      <h6 className="text-red">Phone</h6>
                      <p style={{ marginBottom: 4 }}>+91-63836 23926</p>
                      <p>+91-94432 10651</p>
                    </ul>
                    <ul>
                      <h6 className="text-red">Email</h6>
                      <p>ignisfoundation@gmail.com</p>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default withRouter(ContactUs);
