import React from "react";
import { withRouter } from "react-router";
import { Col } from 'antd';

class IgnisAngelList extends React.Component {
    render() {
        return (
            <div className="ignis-card border-radius">
                <div className="image">
                    <img src={`${this.props.ignisAngelimage}`} alt={`${this.props.ignisAngelName}`} />
                </div>
                <div className="content text-center">
                    <p className="text-blue">{this.props.ignisAngelName}</p>
                </div>
            </div>
        );
    }
}
export default withRouter(IgnisAngelList);