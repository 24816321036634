import React from "react";
import { withRouter } from "react-router";
import Slider from "react-slick";
import "./HomeSlider.scss";

// slider component
const mainSlider = {
  arrows: true,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        arrows: false,
      },
    },
    {
      breakpoint: 900,
      settings: {
        arrows: false,
      },
    },
    {
      breakpoint: 580,
      settings: {
        arrows: false,
      },
    },
    {
      breakpoint: 400,
      settings: {
        arrows: false,
      },
    },
  ],
};

class HomeSlider extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* <!--Main Slider section starts--> */}
        <div className="ignis-slider" id="home">
          <div className="slider-content ">
            <Slider {...mainSlider}>
              <div className="item slider-1">
                <div className="center-contents w-100 p-mob">
                  <div className=" middle-content ">
                    <div className="align-middle slider-caption">
                      <h1 className="font-weight-normal text-white text-center  mb-3">
                        WELCOME TO IGNIS FOUNDATION
                      </h1>
                      <p className="text-white text-center text-uppercase ">
                        Our Vision is to Build <br />
                        an equitable digital world
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item slider-2">
                <div className="center-contents w-100 p-mob">
                  <div className=" middle-content ">
                    <div className="align-middle slider-caption">
                      <h1 className="font-weight-normal text-white text-center  mb-3">
                        WOMENS EMPOWERMENT
                      </h1>
                      <p className="text-white text-center text-uppercase ">
                        Empowerment Training <br />
                        for Rural Women
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item slider-3">
                <div className="center-contents w-100 p-mob">
                  <div className=" middle-content ">
                    <div className="align-middle slider-caption">
                      <h1 className="font-weight-normal text-white text-center  mb-3">
                        PERSONALITY DEVELOPMENT
                      </h1>
                      <p className="text-white text-center text-uppercase ">
                        Youth Self Awareness Camp
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
            {/* <div className="move-down text-center">
                 <img src={scrollDown} alt="Move to another section" />
             </div> */}
          </div>
        </div>
        {/* <!--Main Slider section ends--> */}
      </React.Fragment>
    );
  }
}
export default HomeSlider;
