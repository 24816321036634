import React from "react";
import { withRouter } from "react-router";
import { Row, Col } from "antd";
import Slider from "react-slick";
import SlidingList from "./SlidingList";
import "./OurWorksSection.scss";
import LoyalaCollege2022 from "../../assets/images/our-works/2022/loyola-college-of-engineering/main.jpg";
import tnpscTraining2022 from "../../assets/images/our-works/2022/tnpsc/tnpsc-training-ignis-foundation.jpg";
import englishCoaching2021 from "../../assets/images/our-works/2021/english-coaching/main.jpg";
import tailoringAcademy2018 from "../../assets/images/our-works/2018/trailoring-academey/taloring-class-for-rural-women.jpg";
import pongalCelebration2019 from "../../assets/images/our-works/2019/pongal-festival/pongal-festival.jpg";
import christmas2019 from "../../assets/images/our-works/2019/christmas/christmas-celebration.jpg";
import annualDay2019 from "../../assets/images/our-works/2019/annual-day/annual-day.jpg";
import womenEmpowerment2018 from "../../assets/images/our-works/2018/women-empowerment-camps/women-empowerment-camps.jpg";
import youthSelfAwareness2018 from "../../assets/images/our-works/2018/youth-self-awareness/youth-self-awareness.jpg";
import weareyourVoice2018 from "../../assets/images/our-works/2018/we-are-your-voice-job-fair/we-are-your-voice-job-fair.jpg";
import educationforPoor2018 from "../../assets/images/our-works/2018/educational-services/educational-services.jpg";
import helptoVincent2017 from "../../assets/images/our-works/2022/loyola-college-of-engineering/main.jpg";

// Loyala College
// import loyalaCollegeGallery1 from "../../assets/images/our-works/2022/loyola-college-of-engineering/1.jpg";
// import loyalaCollegeGallery2 from "../../assets/images/our-works/2022/loyola-college-of-engineering/2.jpg";
// import loyalaCollegeGallery3 from "../../assets/images/our-works/2022/loyola-college-of-engineering/3.jpg";
// import loyalaCollegeGallery4 from "../../assets/images/our-works/2022/loyola-college-of-engineering/4.jpg";
// import loyalaCollegeGallery5 from "../../assets/images/our-works/2022/loyola-college-of-engineering/5.jpg";
// import loyalaCollegeGallery6 from "../../assets/images/our-works/2022/loyola-college-of-engineering/6.jpg";
// import loyalaCollegeGallery7 from "../../assets/images/our-works/2022/loyola-college-of-engineering/7.jpg";
// import loyalaCollegeGallery8 from "../../assets/images/our-works/2022/loyola-college-of-engineering/8.jpg";

// const loyalaCollegeGallery = [
//     {
//         imagePath: [loyalaCollegeGallery1],
//         thumbnailImagePath: [loyalaCollegeGallery1],
//     },
//     {
//         imagePath: [loyalaCollegeGallery2],
//         thumbnailImagePath: [loyalaCollegeGallery2],
//     },
//     {
//         imagePath: [loyalaCollegeGallery3],
//         thumbnailImagePath: [loyalaCollegeGallery3],
//     },
//     {
//         imagePath: [loyalaCollegeGallery4],
//         thumbnailImagePath: [loyalaCollegeGallery4],
//     },
//     {
//         imagePath: [loyalaCollegeGallery5],
//         thumbnailImagePath: [loyalaCollegeGallery5],
//     },
//     {
//         imagePath: [loyalaCollegeGallery6],
//         thumbnailImagePath: [loyalaCollegeGallery6],
//     },
//     {
//         imagePath: [loyalaCollegeGallery7],
//         thumbnailImagePath: [loyalaCollegeGallery7],
//     },
//     {
//         imagePath: [loyalaCollegeGallery8],
//         thumbnailImagePath: [loyalaCollegeGallery8],
//     },
// ]

// slider component
const eventGallery = {
  arrows: true,
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 580,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};

class OurWorksSection extends React.Component {
  constructor() {
    super();
    this.state = {
      loyalaCollegeGallery: false,
      recognitionGallery: false,
    };
  }
  onCancel = () => {
    this.setState({ loyalaCollegeGallery: false });
    this.setState({ recognitionGallery: false });
    this.setState({ feedingpoorGallery: false });
    this.setState({ chesscompetitionGallery: false });
    this.setState({ valluvarvizhaGallery: false });
  };
  render() {
    return (
      <React.Fragment>
        <section
          className="event-gallery bg-light-gray pattern-stipped-blue-bl py-5  p-mob"
          id="ourwork"
        >
          <div className="container mx-auto py-4">
            <Row gutter={[30, 20]} align="center" className="text-white pb-4 ">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <h2 className="text-center text-uppercase title-font">
                  Our Works
                </h2>
              </Col>
            </Row>
            <Row gutter={[20, 20]} align="center" className="pb-4">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <Slider {...eventGallery}>
                  {/* <div onClick={() => this.setState({ loyalaCollegeGallery: true })}>
                                        <SlidingList eventMainImg={LoyalaCollege2022} eventYear="2022" eventName="IT Project Training" eventPlace="Loyola College of Engineering, Thovalai, Tamilnadu, India" />
                                    </div> */}
                  <SlidingList
                    eventMainImg={LoyalaCollege2022}
                    eventYear="2022"
                    eventName="IT projects training"
                    eventPlace="Loyola College of Engineering, Thovalai, Tamilnadu, India"
                  />
                  <SlidingList
                    eventMainImg={tnpscTraining2022}
                    eventYear="2022"
                    eventName="Government jobs training"
                    eventPlace="Govt job training, Tamilnadu, India"
                  />
                  <SlidingList
                    eventMainImg={englishCoaching2021}
                    eventYear="2021"
                    eventName="Spoken english training"
                    eventPlace="St Peters College, Tamilnadu, India"
                  />
                  <SlidingList
                    eventMainImg={tailoringAcademy2018}
                    eventYear="2018 to 2020"
                    eventName="Tailoring classes for women (5 Batches)"
                    eventPlace="Vkpuram, Tamilnadu, India"
                  />
                  <SlidingList
                    eventMainImg={pongalCelebration2019}
                    eventYear="2019"
                    eventName="Digital commerce classes"
                    eventPlace="Vkpuram, Tamilnadu, India"
                  />
                  <SlidingList
                    eventMainImg={annualDay2019}
                    eventYear="2019"
                    eventName="Financial literacy camps"
                    eventPlace="Vkpuram, Tamilnadu, India"
                  />
                  <SlidingList
                    eventMainImg={christmas2019}
                    eventYear="2019"
                    eventName="Women empowerment camps"
                    eventPlace="Vkpuram, Tamilnadu, India"
                  />
                  <SlidingList
                    eventMainImg={womenEmpowerment2018}
                    eventYear="2018"
                    eventName="Women craftwork classes "
                    eventPlace="Vkpuram, Tamilnadu, India"
                  />
                  <SlidingList
                    eventMainImg={youthSelfAwareness2018}
                    eventYear="2018"
                    eventName="Youth self awareness camp"
                    eventPlace="Francis of Assisi CBSE school, Tamilnadu, India"
                  />
                  <SlidingList
                    eventMainImg={weareyourVoice2018}
                    eventYear="2018"
                    eventName="Job fair for persons with disabilities"
                    eventPlace="Vkpuram, Tamilnadu, India"
                  />
                  <SlidingList
                    eventMainImg={educationforPoor2018}
                    eventYear="2018"
                    eventName="Education support for poor children"
                    eventPlace="Vkpuram, Tamilnadu, India"
                  />
                </Slider>
              </Col>
            </Row>
          </div>
        </section>

        {/* <ModalBox
                    visible={this.state.loyalaCollegeGallery}
                    width={900}
                    title="IT Project Training, Loyola College of Engineering, Tamilnadu, India (2022)"
                    onCancel={this.onCancel}
                    footer={null}
                    destroyOnClose>
                    <GallerySlider images={loyalaCollegeGallery}/>
                </ModalBox> */}
      </React.Fragment>
    );
  }
}
export default withRouter(OurWorksSection);
