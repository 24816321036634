import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import "./IgnisProjectAcademy.scss";
import arrow from "../../assets/images/arrow.svg";
class IgnisProjectAcademySection extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section
          className="section-padding pattern-stipped-red-br ignis-academy"
          id="ignisproacademy"
        >
          <div className="container">
            <Row gutter={[20, 20]} className="mb-3">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <div class="align-middle">
                  <h2 className="font-weight-bolder title-font text-uppercase mb-2">
                    IGNIS Pro Academy
                  </h2>
                  <p>
                    IGNIS Pro Academy is a specialized technology training
                    division dedicated to delivering role-specific digital
                    technology education. This innovative approach leverages
                    seasoned professionals to offer authentic project
                    experience.
                  </p>
                  <p>
                    IGNIS Pro Academy trains candidates by taking them through
                    an immersive experience.
                  </p>
                  <p>
                    Working with our Architects, Project Managers, Developers,
                    and Designers, you will gain real IT experience. Using this
                    methodology, candidates will be able to learn about
                    real-life situations in a project and gain more confidence.
                  </p>
                </div>
              </Col>
            </Row>
            <Row gutter={[20, 20]}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <div class="details align-middle border border-radius mt-2">
                  <h3 className="font-weight-bolder  text-blue p-3 p-lg-4">
                    Who is this program for?
                  </h3>
                  <ul className="p-0">
                    <li>Students</li>
                    <li>Freshers</li>
                    <li>Job Seekers</li>
                    <li>Career Changers</li>
                    <li>Part Time Workers</li>
                    <li>Freelancers</li>
                    <li>IT Professionals</li>
                    <li>Non IT</li>
                  </ul>
                  <a href=""></a>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <div class="details align-middle border border-radius mt-2">
                  <h3 className="font-weight-bolder text-blue  p-3 p-lg-4">
                    What will you learn in the program?
                  </h3>
                  <ul className="p-0">
                    <li>Project Management</li>
                    <li>UX / UI Development</li>
                    <li>Full Stack Web Development</li>
                    <li>Low code & No code</li>
                    <li>Data Science, AI/ML</li>
                    <li>QA & Devops</li>
                    <li>Digital Marketing</li>
                    <li>ERP / CRM Applications</li>
                  </ul>
                </div>
              </Col>
            </Row>
            <Row gutter={[20, 20]}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                className="mt-4 text-center"
              >
                <a
                  href="https://www.ignisproacademy.com"
                  target="_blank"
                  className="btn btn-link btn-lg"
                >
                  Kick start your skill development training{" "}
                  <img src={arrow} alt="ignis foundation button" />
                </a>
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default IgnisProjectAcademySection;
